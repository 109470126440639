import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { DeviceDetectorModule } from "ngx-device-detector";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PDFViewerComponent } from "./components/pdfviewer/pdfviewer.component";
import { HomeComponent } from "./components/home/home.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ForbiddenComponent } from "./components/forbidden/forbidden.component";
import { CustomerService } from "./services/customer.service";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { GetUserInfoComponent } from "./components/get-user-info/get-user-info.component";
import { PoweredByIwrapperComponent } from "./components/powered-by-iwrapper/powered-by-iwrapper.component";
import { DirectDownloadComponent } from "./components/direct-download/direct-download.component";
import { DownloadPdfComponent } from "./components/download-pdf/download-pdf.component";
import { PaymentGatewayPopupComponent } from "./components/payment-gateway-popup/payment-gateway-popup.component";
import { RecaptchaModule } from "ng-recaptcha";
import { GdprConsentComponent } from "./components/gdpr-consent/gdpr-consent.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { ShowAdsComponent } from "./components/show-ads/show-ads.component";
import { NoSanitizePipe } from "./components/show-ads/NoSanitizePipe";
@NgModule({
  declarations: [
    AppComponent,
    PDFViewerComponent,
    HomeComponent,
    NotFoundComponent,
    ForbiddenComponent,
    HeaderComponent,
    GetUserInfoComponent,
    PoweredByIwrapperComponent,
    DirectDownloadComponent,
    DownloadPdfComponent,
    PaymentGatewayPopupComponent,
    GdprConsentComponent,
    ShowAdsComponent,
    NoSanitizePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    MatIconModule,
    FormsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    MatProgressSpinnerModule,
    MatBottomSheetModule,
  ],
  providers: [CustomerService],
  entryComponents: [
    GetUserInfoComponent,
    PoweredByIwrapperComponent,
    PaymentGatewayPopupComponent,
    DownloadPdfComponent,
    GdprConsentComponent,
    ShowAdsComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
