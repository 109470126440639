import { Component, OnInit, Inject, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, concat } from "rxjs";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { AskForUserInfoService } from "src/app/services/ask-for-user-info.service";
import { MatDialog } from "@angular/material/dialog";
declare var jQuery: any;
declare var $: any;
@Component({
  selector: "app-get-user-info",
  templateUrl: "./get-user-info.component.html",
  styleUrls: ["./get-user-info.component.css"],
})
export class GetUserInfoComponent implements OnInit {
  UserInfoForm: FormGroup;
  UsagePermissionData: any;
  errorExist: boolean = false;
  errorMsg: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userinfService: AskForUserInfoService,
    private dialogRef: MatDialogRef<GetUserInfoComponent>
  ) {}

  ngOnInit() {
    this.loadInitialForm();
    //  console.log(this.data);
  }
  onFormSubmit(UserInfoForm) {
    // console.log(this.UserInfoForm);

    if (this.UserInfoForm.status == "INVALID") {
      return false;
    }
    this.userinfService.UserInfoData(this.UserInfoForm.value).subscribe(
      (res: any) => {
        //  console.log(res);
        if (res.CheckCon) {
          this.dialogRef.close({
            userData: this.UserInfoForm.value,
          });
        } else {
          this.errorExist = true;
          this.errorMsg = res.message;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  async loadInitialForm() {
    var lines;
    await $.get("https://www.cloudflare.com/cdn-cgi/trace", function (data) {
      //console.log(data);
      lines = data.split("\n");
    });
    //console.log(lines);
    this.UserInfoForm = this.formBuilder.group({
      refPublicationGroupId: [this.data[1].idPublicationGroup],
      UsageType: ["view"],
      browser: [lines[5].split("=")[1]],
      ipAddress: [lines[2].split("=")[1]],
      accessType: [null],
      customerId: [this.data[3].idCustomer],
      RefGroupId: [this.data[0].idGroup],
      idPublication: [this.data[2].idPublication],
      publicationTitle: [this.data[2].publicationName],
      emailaddress: [null],
      phoneno: [null],
      passcode: [null],
      organization: [null],
      fullName: [""],
    });
  }
}
