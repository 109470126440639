import { Component, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { environment } from "../../../environments/environment";
declare var $: any;
@Component({
  selector: "app-gdpr-consent",
  templateUrl: "./gdpr-consent.component.html",
  styleUrls: ["./gdpr-consent.component.css"],
})
export class GdprConsentComponent implements OnInit {
  GDPRConsent: boolean = false;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<GdprConsentComponent>
  ) {}

  ngOnInit() {}
  rejectAll() {
    this.GDPRConsent = true;
  }
  acceptAll() {
    this._bottomSheetRef.dismiss(true);
  }
  refererlink() {
    if (environment.fileReferrer) {
      window.open(environment.fileReferrer, "_self");
    } else {
      window.open(
        "https://www.iwrapper.com/terms-and-condition/?cookiepolicy",
        "_self"
      );
    }
  }
}
