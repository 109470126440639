import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AskForUserInfoService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) {}
  UserInfoData(data) {
    return this.http.post(this.apiURL + "askforuserinfo", data);
  }
}
